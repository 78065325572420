import React from "react"
import { Link } from "gatsby"
import styles from './YoutubeWrap.module.css';

const YoutubeWrap = ({
  src
}) => (
  <div className={styles.embedContainer}><iframe src={src} width='100%' frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe></div>
)

export default YoutubeWrap

import React from "react"
import { Link, graphql } from "gatsby"
import Img from "gatsby-image"

import Layout from "../../components/layout"
import ProjectPageWrapper from '../../components/project_page/ProjectPageWrapper';
import TextBlock from '../../components/project_page/TextBlock';
import Mobile3 from '../../components/project_page/Mobile3';
import Web1 from '../../components/project_page/Web1';
import Image1 from '../../components/project_page/Image1';
import ImageGrid from '../../components/project_page/ImageGrid';
import YoutubeWrap from '../../components/project_page/image_wrappers/YoutubeWrap';

import globalStyles from '../global.module.css';

const headerBackground = "#fffade"
const navColor="#222";
const Warmupz = ({ data, location  }) => (
  <Layout headerBackground={headerBackground} navColor={navColor}>
    <ProjectPageWrapper
      fromList={location && location.state && location.state.fromList}
      title="Warmupz"
      oneLiner="Get your meetings flowing"
      link="https://warmupz.com"
      image={<Img fluid={data.header.childImageSharp.fluid} />}
      mobileImage={<Img fluid={data.mobileHeader.childImageSharp.fluid} />}
      headerBackground={headerBackground}
    >
      <div className={`${globalStyles.marginBottomNormal}`}>
        <TextBlock
          title="The Pain"
          headline={`Getting everyone in a meeting on the same page`}
          content={`
            If you workout without warming up you’re gonna have a bad time - same goes for meetings. Going into a meeting, everyones in a different headspace:
          `}
        />
        <Image1 maxWidth={600} align={'left'} img={<Img fluid={data.body1.childImageSharp.fluid} />} />
      </div>
      <div className={`${globalStyles.marginBottomBig}`}>
        <TextBlock
          title="The Product"
          headline={`A big ‘ol stack of quick meeting warm ups to get any group in sync`}
          content={`Meetloaf automatically creates and schedules virtual 1:1s
            for your teammates to get to know each other better, catchup and
            learn something new. Whether you're a team of 10 or 1,000 - Meetloaf
            pairs and schedules everyone in under a minute.`
          }
        />
        <Image1 maxWidth={600} align={'left'} img={<Img fluid={data.body2.childImageSharp.fluid} />} />

      </div>
      <div className={`${globalStyles.marginBottomNormal}`}>
        <Web1
          desktopImage={<Img fluid={data.body3.childImageSharp.fluid} />}
          mobileImage={<Img fluid={data.body3.childImageSharp.fluid} />}
        />
      </div>
      <div className={`${globalStyles.marginBottomHuge}`}>
        <ImageGrid images={[
          <Img fluid={data.body4.childImageSharp.fluid} />,
          <Img fluid={data.body5.childImageSharp.fluid} />,
          <Img fluid={data.body6.childImageSharp.fluid} />,
        ]} />
      </div>

      <div>
        <div className={`${globalStyles.marginBottomBig}`}>
            <TextBlock
              title="Engineering"
              headline={`Quick and easy`}
              content={`
                We use Gatsby to pre-render all of our warmup pages for faster
                load times so everyone can get their meetings started on time.
              `
              }
            />
        </div>
        <Image1 maxWidth={'800px'} img={<Img fluid={data.body7.childImageSharp.fluid} />} />
      </div>
    </ProjectPageWrapper>
  </Layout>
)

export default Warmupz

export const pageQuery = graphql`
  query {
    header: file(relativePath: { eq: "projects/warmupz/header.png" }) {
      childImageSharp {
        fluid(maxWidth:2000) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    mobileHeader: file(relativePath: { eq: "projects/warmupz/header-mobile.png" }) {
      childImageSharp {
        fluid(maxWidth:2000) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    body1: file(relativePath: { eq: "projects/warmupz/meerkat0.png" }) {
      childImageSharp {
        fluid(maxWidth:2000) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    body2: file(relativePath: { eq: "projects/warmupz/meerkat1.png" }) {
      childImageSharp {
        fluid(maxWidth:2000) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    body3: file(relativePath: { eq: "projects/warmupz/web_warmup.png" }) {
      childImageSharp {
        fluid(maxWidth:2000) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    body4: file(relativePath: { eq: "projects/warmupz/peeps1.png" }) {
      childImageSharp {
        fluid(maxWidth:2000) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    body5: file(relativePath: { eq: "projects/warmupz/peeps2.png" }) {
      childImageSharp {
        fluid(maxWidth:2000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    body6: file(relativePath: { eq: "projects/warmupz/peeps3.png" }) {
      childImageSharp {
        fluid(maxWidth:2000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    body7: file(relativePath: { eq: "projects/warmupz/warmupz6.png" }) {
      childImageSharp {
        fluid(maxWidth:2000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
